/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import clsx from 'clsx';
import { CustomerReviewsProps } from 'interfaces/cms/content';
import ParagraphHeader from 'components/ParagraphHeader';
import Swiper from 'components/common/Swiper';
import useMediaQuery from 'hooks/useMediaQuery';
import CustomerReview from './Review';
import { isAsiadirect, rem } from 'lib';
import Script from 'next/script';

function CustomerReviews({
  header,
  reviews,
  background,
  productName,
  featureImageUrl,
  brand,
  productDescription,
  averageRatingValue,
  totalReviewCount,
}: CustomerReviewsProps) {
  const [isMobile] = useMediaQuery();
  const slidesPerViewVariant = isMobile ? 1 : 3;
  const swiperColor = isAsiadirect() ? '#58b8e0' : '#005098';
  const swiperPaginationBullet = isAsiadirect()
    ? `.swiper-pagination-bullet {
    width: ${rem(12)};
    height: ${rem(12)};
    background: #e9edf5;
  }`
    : `.swiper-pagination-bullet {
    margin: 0 ${rem(4)};
    width: ${rem(15)};
    height: ${rem(13)};
    background: url('https://rabbit-care-images.imgix.net/heart_44c1869696/heart_44c1869696.svg');
    background-repeat: no-repeat;
  }`;

  function getReviewStructuredData() {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productName,
      image: featureImageUrl,
      brand: {
        '@type': 'Brand',
        name: brand,
      },
      description: productDescription,
      reviews: reviews?.map(({ review, rating, customerName }) => ({
        '@type': 'Review',
        reviewBody: review,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: rating,
          bestRating: 5,
        },
        author: {
          '@type': 'Person',
          name: customerName,
        },
      })),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: averageRatingValue,
        reviewCount: totalReviewCount,
      },
    };
  }

  function ReviewData() {
    if (isMobile && reviews.length < 2) {
      return (
        <div className="flex items-center justify-center py-0 px-[0.625rem]">
          {reviews.map((review) => (
            <CustomerReview {...review} key={review.id} />
          ))}
        </div>
      );
    }

    if (!isMobile && reviews.length < 4) {
      return (
        <div className="flex items-center justify-center">
          {reviews.map((review) => (
            <div
              className="p-[0.9375rem] w-[100%] max-w-[20rem]"
              key={review.id}
            >
              <CustomerReview {...review} />
            </div>
          ))}
        </div>
      );
    }

    return (
      <Swiper
        slidesPerView={slidesPerViewVariant}
        navigation
        pagination
        styles={`
        .swiper {
          padding: 0 ${rem(20)} ${rem(50)};
          margin: 0 -20px;
          z-index: 0;
        }
      
        .swiper .swiper-wrapper .swiper-slide:last-child {
          margin-right: 0;
        }
      
        @media only screen and (max-width: 768px) {
          .swiper {
            padding: 0 ${rem(10)} ${rem(40)};
            margin: 0;
          }
        }
      
        .swiper-pagination {
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: ${rem(14)};
        }
      
        .swiper-button-prev,
        .swiper-button-next {
          margin-top: ${rem(-30)};
          width: ${rem(40)};
          height: ${rem(40)};
          color: ${swiperColor};
        }

        .swiper-button-prev svg,
        .swiper-button-next svg {
          width: ${rem(20)};
          height: ${rem(20)};
        }
      
        .swiper-button-prev {
          left: ${rem(4)};
        }
      
        .swiper-button-next {
          right: ${rem(4)};
        }
      
        .swiper-button-prev,
        .swiper-button-next {
          font-size: ${rem(18)};
          font-weight: bold;
          background-color: #fff;
          box-shadow: 0 ${rem(3)} ${rem(6)} #0000001a;
          border: ${rem(1)} solid #f4f8fe;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      
        ${swiperPaginationBullet}

        @media only screen and (max-width: 768px) {
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }
        `}
      >
        {reviews.map((review) => (
          <CustomerReview {...review} key={review.id} />
        ))}
      </Swiper>
    );
  }

  return (
    <div
      className={clsx('px-0 py-[1.875rem] w-[100%] customer_reviews')}
      style={background && { backgroundColor: background }}
    >
      <Script
        id="dynamicJsonLD"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getReviewStructuredData()),
        }}
        strategy="beforeInteractive"
      />
      {header && (
        <div className="max-w-[69.375rem] mx-auto my-0 px-[1.25rem] pb-[1.875rem]">
          <ParagraphHeader {...header} />
        </div>
      )}
      <div
        className={clsx(
          'max-w-[68rem] py-0 px-[0.3125rem] my-0 mx-auto md:px-[1.25rem]',
          !isAsiadirect() && 'heartIcon'
        )}
      >
        <ReviewData />
      </div>
    </div>
  );
}

export default CustomerReviews;
