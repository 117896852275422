import React, { PropsWithChildren, useRef, useEffect } from 'react';
import { register } from 'swiper/element/bundle';

register();

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'swiper-container': any;
      'swiper-slide': any;
    }
  }
}

export interface SliderProps {
  slidesPerView?: number;
  spaceBetween?: number;
  pagination?: boolean;
  navigation?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  styles?: string;
}

function Slider({
  slidesPerView = 3,
  spaceBetween = 35,
  navigation = false,
  pagination = false,
  autoPlay = true,
  loop = false,
  styles,
  children,
}: PropsWithChildren<SliderProps>) {
  const checkPagination = () => {
    if (navigation && React.Children.count(children) > slidesPerView) {
      return true;
    }
    return false;
  };

  const showPagination = checkPagination();

  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperElRef.current;
    const params = {
      ...(Boolean(autoPlay) && {
        autoPlay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      }),
      allowTouchMove: true,
      ...(Boolean(showPagination) && { navigation }),
      ...(Boolean(pagination) && {
        pagination: {
          clickable: true,
        },
      }),
      slidesPerView,
      spaceBetween,
      resizeObserver: true,
      updateOnWindowResize: true,
      loop,
      // inject modules styles to shadow DOM
      injectStyles: [styles],
    };

    Object.assign(swiperContainer, params);

    swiperContainer.initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <swiper-container ref={swiperElRef} init={false}>
      {React.Children.map(children, (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <swiper-slide key={index}>{child}</swiper-slide>
      ))}
    </swiper-container>
  );
}

export default Slider;
